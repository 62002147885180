import React, { useState } from 'react'
import '../styles/LoginForm.css'
import toast from 'react-hot-toast';
import axios from 'axios';

const ForgetPass = () => {
    const [user, setUser] = useState({
        email: '',
    })

    const [disabled, setDisabled] = useState(false)
    const [time, setTime] = useState(30)

    const onchangeUser = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const onLogin = (e) => {
        e.preventDefault();
        if (user.email === '') {
            toast.error('Please Enter Email Address!')
        }
        else {
            axios.post(`${process.env.REACT_APP_API_HOST}/auth/reset-token-admin`, { email: user.email }
            ).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                    setDisabled(true)
                    const interval = setInterval(() => {
                        setTime((time) => time - 1)
                    }, 1000)
                    setTimeout(() => {
                        clearInterval(interval)
                        setTime(30)
                        setDisabled(false)
                    }, 30000)
                }
                else {
                    toast.error(res.data.message)
                }
            }).catch((err) => {
                toast.error('Server Error!')
            })
        }
    }

    return (
        <div className='admin-login flex-column'>
            <img src='./assets/logo.svg' alt='admin-logo' />
            <div className="form-card">
                <h2 className="form-heading mb-3"><img src='./assets/admin-icon.svg' alt='admin-icon' /> <span className='mx-3'>Forget Password</span></h2>
                <form onSubmit={onLogin} className="login-form">
                    <div className="input-group">
                        <input type="email" name="email" placeholder="Email" className="form-input" onChange={onchangeUser} />
                        <i className='bx bx-envelope input-icon'></i>
                    </div>
                    <button className="form-btn login-btn" type='submit' disabled={disabled}>Send Email!</button>
                    {disabled && <p className='text-center mt-3'>Resend in {time} seconds</p>}
                </form>
            </div>
        </div>

    )
}

export default ForgetPass