import axios from 'axios';
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST

export const userLogin = (login) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_LOGIN_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/login`, login);
            localStorage.setItem('xat', data.token);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'USER_LOGIN_SUCCESS', user: data });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'USER_LOGIN_FAILURE', error: error.message });
        }
    }
}

export const adminLogin = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ADMIN_LOGIN_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/admin/login`, user);
            localStorage.setItem('xat', data.token);
            toast.success('Admin Logged in successfully!');
            dispatch({ type: 'ADMIN_LOGIN_SUCCESS', user: data });
        }
        catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ADMIN_LOGIN_FAILURE', error: error.message });
        }
    }
}

export const createSubAccount = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUB_ACCOUNT_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }

            const { data } = await axios.post(`${API_URL}/auth/sub-account`, user, config)
            toast.success("Sub Account Created Successfully!")
            dispatch({ type: 'SUB_ACCOUNT_SUCCESS', user: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'SUB_ACCOUNT_FAILURE', error: error.response.data.error });
        }
    }
}


export const editSubAccount = (sub, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'EDIT_SUB_ACCOUNT_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/auth/sub-account/${id}`, sub, config)
            toast.success("User Updated Successfully!")
            if (data.type === 'psychologist') {
                dispatch({ type: 'EDIT_PSYCHOLOGIST_SUCCESS', user: data })
            }
            else if (data.type === 'end-user') {
                dispatch({ type: 'EDIT_PATIENT_SUCCESS', user: data })
            }
            else {
                dispatch({ type: 'EDIT_SUB_ACCOUNT_SUCCESS', user: data })
            }
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'EDIT_SUB_ACCOUNT_FAILURE', error: error.response.data.error });
        }
    }
}



export const getAllPatients = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_PATIENT_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/patient`, config)
            dispatch({ type: 'GET_ALL_PATIENT_SUCCESS', payload: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_ALL_PATIENT_FAILURE', error: error.response.data.error });
        }
    }
}
export const getAllSubAccounts = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/sub-account`, config)
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_SUCCESS', payload: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_FAILURE', error: error.response.data.error });
        }
    }
}

export const getAllPsychologists = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_PSYCHOLOGISTS_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/psychologist`, config)
            dispatch({ type: 'GET_ALL_PSYCHOLOGISTS_SUCCESS', payload: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_ALL_PSYCHOLOGISTS_FAILURE', error: error.response.data.error });
        }
    }

}


export const logout = () => {
    return async (dispatch) => {
        try {
            localStorage.removeItem('xat')
            dispatch({ type: 'LOGOUT' })
            toast.success('Logged out successfully!')
        }
        catch (error) {
            toast.error(error.response.data.error);
        }
    }
}

export const deleteSubAccount = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'DELETE_SUB_ACCOUNT_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/auth/sub-account/${id}`, config)
            toast.success("Sub Account Deleted Successfully!")
            dispatch({ type: 'DELETE_SUB_ACCOUNT_SUCCESS', user: data.user })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'DELETE_SUB_ACCOUNT_FAILURE', error: error.response.data.error });
        }
    }
}


export const getUserToken = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_USER_REQUEST' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem("xat")
                }
            }

            const { data } = await axios.get(`${API_URL}/auth/user`, config)

            dispatch({ type: 'GET_USER_SUCCESS', user: data.user })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_USER_FAILURE', error: error.response.data.error });
        }
    }
}