import React from 'react'
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Protected = ({ children }) => {

    const { isAuthenticated } = useSelector(state => state.Auth);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return children;
}

export default Protected