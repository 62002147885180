import axios from 'axios';
import React from 'react';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import '../../styles/LoginForm.css';

const ChangePasswordModal = ({ isOpen, onClose, onChangePass, setProfile, profile, onHandleProfile }) => {

    const showOldPass = () => {
        const x = document.getElementById('oldPassword')
        if (x.type === 'password') {
            x.type = 'text'
        } else {
            x.type = 'password'
        }
    }

    const showNewPass = () => {
        const x = document.getElementById('newPassword')
        if (x.type === 'password') {
            x.type = 'text'
        } else {
            x.type = 'password'
        }
    }

    const showConfirmPass = () => {
        const x = document.getElementById('confirmPassword')
        if (x.type === 'password') {
            x.type = 'text'
        } else {
            x.type = 'password'
        }
    }

    const isValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }



    const onChangeEmail = () => {
        if (profile.email === '') {
            toast.error('Email is required')
            return
        }
        else {
            if (!isValid(profile.email)) {
                toast.error('Invalid email')
                return
            }
            else {
                axios.post(`${process.env.REACT_APP_API_HOST}/auth/change-email`, {
                    email: profile.email
                }, {
                    headers: {
                        'x-auth-token': localStorage.getItem('xat')
                    }
                }).then(res => {
                    toast.success(res.data.message)
                    setProfile({ ...profile, email: res.data.email })
                }).catch(err => {
                    toast.error(err.response.data.message)
                })
            }

        }

    }


    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            },
            content: {
                width: '50%',
                margin: 'auto',
                height: 'max-content',
                padding: '20px',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
            }
        }}>
            <h4 className='text-center'>Settings</h4>
            <div className="divider" style={{
                margin: '20px 0',
                borderBottom: '1px solid #e0e0e0'
            }}></div>
            <div className="row">
                <div className="d-flex">
                    <div className='w-100'>
                        <label htmlFor="email">Email</label>
                        <div className="input-group">
                            <input type="email" placeholder="Email" className="form-input" name='email' onChange={onHandleProfile} value={profile.email} />
                            <i className='bx bx-envelope input-icon'></i>
                        </div>
                    </div>
                    <div>
                        <button className='cancel-modal-btn mx-1 mt-4' onClick={onChangeEmail}>Update</button>
                    </div>
                </div>

            </div>

            <div className="divider"
                style={{
                    margin: '20px 0',
                    borderBottom: '1px solid #e0e0e0'
                }}></div>
            <h4 className='text-center my-3'>Change Password</h4>
            <div className="row">
                <label htmlFor="oldPassword">Old Password</label>
                <div className="input-group">
                    <input type="password" placeholder="Old Password" className="form-input" name='oldPassword' onChange={onHandleProfile} value={profile.oldPassword} id='oldPassword' />
                    <i className='bx bx-low-vision input-icon' onClick={showOldPass}></i>
                </div>

                <label htmlFor="password">New Password</label>
                <div className="input-group">
                    <input type="password" placeholder="New Password" className="form-input" name='password' onChange={onHandleProfile} value={profile.password} id='newPassword' />
                    <i className='bx bx-low-vision input-icon' onClick={showNewPass}></i>
                </div>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <div className="input-group">
                    <input type="password" placeholder="Confirm Password" className="form-input" name='confirmPassword' onChange={onHandleProfile} value={profile.confirmPassword} id='confirmPassword' />
                    <i className='bx bx-low-vision input-icon' onClick={showConfirmPass}></i>
                </div>

            </div>


            <div className='d-flex justify-content-end'>
                <button className='delete-modal-btn mx-1' onClick={onChangePass}>Update</button>
                <button className='cancel-modal-btn mx-1' onClick={onClose}>Cancel</button>
            </div>
        </Modal>
    );
};

export default ChangePasswordModal;
