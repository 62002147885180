const initialState = {
    loading: false,
    isAuthenticated: false,
    user: {},
    subAccounts: [],
    psychologists: [],
    patients: [],
    redirect: false,
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case 'ADMIN_LOGIN_REQUEST':
            return { ...state, loading: true };
        case 'ADMIN_LOGIN_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'ADMIN_LOGIN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_ALL_SUB_ACCOUNTS_REQ':
            return { ...state, loading: true };
        case 'GET_ALL_SUB_ACCOUNTS_SUCCESS':
            return { ...state, loading: false, subAccounts: action.payload };
        case 'GET_ALL_SUB_ACCOUNTS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SUB_ACCOUNT_REQ':
            return { ...state, loading: true };
        case 'SUB_ACCOUNT_SUCCESS':
            return { ...state, loading: false, subAccounts: [action.user, ...state.subAccounts] };
        case 'SUB_ACCOUNT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'LOGOUT':
            return { ...state, loading: false, isAuthenticated: false, user: {}, subAccounts: [] };
        case 'DELETE_SUB_ACCOUNT_REQ':
            return { ...state, loading: true };
        case 'DELETE_SUB_ACCOUNT_SUCCESS':
            return { ...state, loading: false, subAccounts: state.subAccounts?.filter(sub => sub._id !== action.user) };
        case 'DELETE_SUB_ACCOUNT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_ALL_PSYCHOLOGISTS_REQ':
            return { ...state, loading: true };
        case 'GET_ALL_PSYCHOLOGISTS_SUCCESS':
            return { ...state, loading: false, psychologists: action.payload };
        case 'GET_ALL_PSYCHOLOGISTS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_ALL_PATIENT_REQ':
            return { ...state, loading: true };
        case 'GET_ALL_PATIENT_SUCCESS':
            return { ...state, loading: false, patients: action.payload };
        case 'GET_ALL_PATIENT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'EDIT_SUB_ACCOUNT_REQ':
            return { ...state, loading: true };
        case 'EDIT_SUB_ACCOUNT_SUCCESS':
            return { ...state, loading: false, subAccounts: state.subAccounts?.map(sub => sub._id === action.user._id ? action.user : sub) };
        case 'EDIT_PSYCHOLOGIST_SUCCESS':
            return { ...state, loading: false, psychologists: state.psychologists?.map(sub => sub._id === action.user._id ? action.user : sub) };
        case 'EDIT_PATIENT_SUCCESS':
            return { ...state, loading: false, patients: state.patients?.map(sub => sub._id === action.user._id ? action.user : sub) };
        case 'EDIT_SUB_ACCOUNT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_USER_REQUEST':
            return { ...state, loading: true };
        case 'GET_USER_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'GET_USER_FAILURE':
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    }
};

export default Auth;