import React, { Component } from 'react'
import { Outlet } from 'react-router-dom';
import Header from './Header';

export class HeaderLayout extends Component {
    render() {
        return (
            <>
                <Header />
                <Outlet />
            </>
        )
    }
}

export default HeaderLayout