import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Header.css'
import { logout } from '../app/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import ChangePasswordModal from './Modals/ChangePasswordModal';
import toast from 'react-hot-toast';
import axios from 'axios';

const Header = () => {
    const redirect = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.Auth)
    const [isOpen, setIsOpen] = useState(false);
    const [profile, setProfile] = useState({
        email: '',
        oldPassword: '',
        password: '',
        confirmPassword: ''
    })

    useEffect(() => {
        setProfile({ email: user?.email })
    }, [user])

    const onHandleProfile = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value })
    }

    const onChangePass = () => {
        if (profile.oldPassword === '' || profile.password === '' || profile.confirmPassword === '') {
            toast.error('All fields are required')
            return
        }
        if (profile.password !== profile.confirmPassword) {
            toast.error('Password does not match')
        }
        if (profile.password.length < 5) {
            toast.error('Password must be at least 5 characters')
        }
        else {

            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            axios.post(`${process.env.REACT_APP_API_HOST}/auth/change-password`, {
                oldPassword: profile.oldPassword,
                password: profile.password,
            }, config).then(res => {
                toast.success(res.data.message)
                setIsOpen(false)
                setProfile({ ...profile, oldPassword: '', password: '', confirmPassword: '' })
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }

    const onClose = () => {
        setIsOpen(false)
    }

    const onLogout = () => {
        dispatch(logout())
        redirect('/')
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg nav-color mb-3">
                <div className="container-fluid">
                    <Link to="/">
                        <img className="navbar-brand" src='./assets/logo.svg' alt='logo' />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <span className="navbar-text">
                            <ul className="list-unstyled">
                                <li className="dropdown ml-2">
                                    <div className="avatar avatar-md avatar-indicators avatar-online">
                                        <img alt="avatar" src="./assets/profile.svg" className="rounded-circle" />
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownUser">
                                        <div className="">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <span className="dropdown-item" onClick={() => setIsOpen(true)}>
                                                        <i className="bx bx-user dropdown-icon" style={{ color: "#367DB0" }} ></i>Settings
                                                    </span>
                                                </li>
                                                <div className="dropdown-divider"></div>
                                                <li>
                                                    <span className="dropdown-item" onClick={onLogout}><i className="bx bx-log-out-circle dropdown-icon" style={{ color: "#F24E1E" }}></i>Logout</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
            </nav>
            <ChangePasswordModal isOpen={isOpen} onClose={onClose} onHandleProfile={onHandleProfile} profile={profile} setProfile={setProfile} onChangePass={onChangePass} />
        </>
    )
}

export default Header