import React, { useState } from 'react'
import '../styles/LoginForm.css'
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const redirect = useNavigate()

    const token = window.location.pathname.split('/')[2]

    const [user, setUser] = useState({
        password: '',
        confirmPassword: ''
    })


    const onchangeUser = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    const onLogin = (e) => {
        e.preventDefault();
        if (user.password === '' || user.confirmPassword === '') {
            toast.error('Please Enter Password!')
        }
        else if (user.password !== user.confirmPassword) {
            toast.error('Password does not match!')
        }
        else {
            axios.post(`${process.env.REACT_APP_API_HOST}/auth/reset-password/${token}`, { password: user.password, type: 'sub-psychologist' }
            ).then((res) => {
                if (res.data) {
                    toast.success(res.data.message)
                    redirect('/')
                }
                else {
                    toast.error(res.data.message)
                }
            }).catch((err) => {
                toast.error('Server Error!')
            })
        }
    }

    return (
        <div className='admin-login flex-column'>
            <img src='../assets/logo.svg' alt='admin-logo' />
            <div className="form-card">
                <h2 className="form-heading mb-3"><img src='../assets/admin-icon.svg' alt='admin-icon' /> <span className='mx-3'>Forget Password</span></h2>
                <form onSubmit={onLogin} className="login-form">
                    <div className="input-group">
                        <input type="password" name="password" placeholder="New Password" className="form-input" onChange={onchangeUser} />
                        <i className='bx bx-lock input-icon'></i>
                    </div>
                    <div className="input-group">
                        <input type="password" name="confirmPassword" placeholder="Confirm Password" className="form-input" onChange={onchangeUser} />
                        <i className='bx bx-lock input-icon'></i>
                    </div>
                    <button className="form-btn login-btn" type='submit'>Change Password!</button>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword