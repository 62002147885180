import React, { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

const Profile = () => {

    const [sigPad, setSigPad] = useState({})
    const clear = () => sigPad?.clear()
    // const signature = ''
    const signature = sigPad?.getTrimmedCanvas().toDataURL('image/png')

    const saveSignature = () => {
        console.log(signature)
    }


    return (
        <div className="section">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-12 col-md-5">
                        <h1>Profile</h1>
                        <p>Update your profile information</p>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="d-flex justify-content-end mb-5">

                            <button className="subs-btn header-btn btn-start mx-2">
                                Update Subscription
                            </button>
                            <button className="send-btn header-btn btn-login mx-2">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">

                        <form action="" className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <div className="input-group">
                                <input type="text" placeholder="First Name" className="form-input" />
                                <i className='bx bx-user input-icon'></i>
                            </div>
                            <label htmlFor="lastName">Last Name</label>
                            <div className="input-group">
                                <input type="text" placeholder="Last Name" className="form-input" />
                                <i className='bx bx-user input-icon'></i>
                            </div>
                            <label htmlFor="email">Email</label>
                            <div className="input-group">
                                <input type="email" placeholder="Email" className="form-input" />
                                <i className='bx bx-envelope input-icon'></i>
                            </div>
                            <label htmlFor="password">Package</label>
                            <div className="input-group">
                                <input type='text' placeholder="Package" className="form-input" />
                                <i className='bx bx-package input-icon'></i>
                            </div>
                            <label htmlFor="password">Create Signature</label>
                            <div className="input-group">
                                <SignatureCanvas penColor='black'
                                    canvasProps={{ width: 530, height: 200, className: 'sigCanvas' }} ref={(ref) => { setSigPad(ref) }} />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center">
                        <div className="sig-preview">
                            <img src={signature} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile