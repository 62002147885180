import { React, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Profile from './pages/Profile';
import HeaderLayout from './components/HeaderLayout';
import Protected from './components/Protected';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatients, getAllPsychologists, getAllSubAccounts, getUserToken } from './app/actions/authActions';
import ForgetPass from './pages/ForgetPass';
import ResetPassword from './pages/ResetPassword';
import { logout } from './app/actions/authActions';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const UserList = lazy(() => import('./pages/UserList'));
const Page404 = lazy(() => import('./components/404/Page404'));

function App() {
  console.log("Build Date: 20-MAY-2024")

  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.Auth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllSubAccounts())
      dispatch(getAllPsychologists())
      dispatch(getAllPatients())
      dispatch(getUserToken())
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path='/profile' element={<Protected><Profile /></Protected>} />
              <Route path='/dashboard' element={<Protected><Dashboard /></Protected>} />
              <Route path='/psychologists' element={<Protected><UserList type="psychologist" name="Psychologists" /></Protected>} />
              <Route path='/sub-accounts' element={<Protected><UserList type="sub-accounts" name="Sub Accounts" /></Protected>} />
              <Route path='/patients' element={<Protected><UserList type="patients" name="End Users" /></Protected>} />
            </Route>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Page404 />} />
            <Route path='/forget-password' element={<ForgetPass />} />
            <Route path='/change-password/:token' element={<ResetPassword />} />
          </Routes>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </Router>
    </>
  );
}

export default App;
